import { request } from './utils';

import {
  GetAnalyticRequestOptions,
  AnalyticResponse,
  RevenueDailyResponse,
  CompanyDataDailyResponse,
  DashboardChannelListResponse,
  GetChannelAnalyticRequestOptions,
  ChannelPieChartRecordsResponse,
  GetChannelTypeRequestOptions,
  ChannelSubscriptionReferralsResponse,
  GetChannelSubscriptionReferralsRequestOptions,
  GetChannelRefundStatisticsRequestOptions,
  ChannelRefundStatisticsResponse
} from './interfaces';

export function getRevenueDaily({ query }: GetAnalyticRequestOptions = {}): Promise<RevenueDailyResponse> {
  return request({
    url: '/analytics/revenue/daily',
    method: 'get',
    params: query
  });
}

export function getCompanyDataDaily({ query }: GetAnalyticRequestOptions = {}): Promise<CompanyDataDailyResponse> {
  return request({
    url: '/analytics/revenue/companyDataDaily',
    method: 'get',
    params: query
  });
}

export function getChannelTodayRecord({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/todayRecord',
    method: 'get',
    params: query
  });
}

export function getChannelPeriodStatistic({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/periodStatistic',
    method: 'get',
    params: query
  });
}

export function getChannelRecord({ query }: GetAnalyticRequestOptions = {}): Promise<DashboardChannelListResponse> {
  return request({
    url: '/analytics/channel/records',
    method: 'get',
    params: query
  });
}

export function getUserLineChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/userLineChart',
    method: 'get',
    params: query
  });
}

export function getRevenueLineChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/revenueLineChart',
    method: 'get',
    params: query
  });
}

export function getUserPieChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/userPieChart',
    method: 'get',
    params: query
  });
}

export function getRevenuePieChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/revenuePieChart',
    method: 'get',
    params: query
  });
}

// for Campaign
export function getUserPieChartRecordsByCampaign({
  channelId,
  query
}: GetChannelAnalyticRequestOptions): Promise<ChannelPieChartRecordsResponse> {
  return request({
    url: `/analytics/channel/${channelId}/userPieChart`,
    method: 'get',
    params: query
  });
}

export function getRevenuePieChartRecordsByCampaign({
  channelId,
  query
}: GetChannelAnalyticRequestOptions): Promise<ChannelPieChartRecordsResponse> {
  return request({
    url: `/analytics/channel/${channelId}/revenuePieChart`,
    method: 'get',
    params: query
  });
}

export function getChannelTypePieChart({ query }: GetChannelTypeRequestOptions): Promise<ChannelPieChartRecordsResponse> {
  return request({
    url: '/analytics/channel/type-revenue-distribution',
    method: 'get',
    params: query
  });
}

export function getSubscriptionReferrals({
  query
}: GetChannelSubscriptionReferralsRequestOptions): Promise<ChannelSubscriptionReferralsResponse> {
  return request({
    url: '/analytics/channel/subscriptionReferrals',
    method: 'get',
    params: query
  });
}

export function getRefundStatistics({
  query
}: GetChannelRefundStatisticsRequestOptions): Promise<ChannelRefundStatisticsResponse> {
  return request({
    url: '/analytics/channel/refundStatistics',
    method: 'get',
    params: query
  });
}
